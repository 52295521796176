import { Button, Navbar } from "react-daisyui";

export default function Navigation() {
  return (
    <div className="drawer drawer-end z-50">
      <input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content flex flex-col">
        <Navbar className="px-12 font-sans justify-end md:justify-center">
          <div className="flex-none md:hidden">
            <label htmlFor="my-drawer-3" className="btn btn-square btn-ghost">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-6 h-6 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </label>
          </div>
          <Navbar.Center className="md:block hidden">
            <a
              href="https://github.com/markings31?tab=repositories"
              rel="noreferrer"
              target="_blank"
            >
              <Button
                className="mx-2 btn-sm hover:text-primary-focus"
                color="ghost"
              >
                Portfolio
              </Button>
            </a>
            <a
              href="https://drive.google.com/file/d/11lCQdcXIpgfkk1M1tyHg-gUx__wBSvwG/view?usp=sharing"
              rel="noreferrer"
              target="_blank"
            >
              <Button
                className="mx-2 btn-sm hover:text-primary-focus"
                color="ghost"
              >
                Resume
              </Button>
            </a>
            <a
              href="https://brandonwinecki.substack.com/"
              rel="noreferrer"
              target="_blank"
            >
              <Button
                className="mx-2 btn-sm text-green-400 hover:text-green-700"
                color="ghost"
              >
                VitaLife
              </Button>
            </a>
            <a
              href="https://github.com/markings31"
              rel="noreferrer"
              target="_blank"
            >
              <Button
                className="mx-2 btn-sm hover:text-primary-focus"
                color="ghost"
              >
                GitHub
              </Button>
            </a>
            <a
              href="https://linkedin.com/in/brandonwinecki"
              rel="noreferrer"
              target="_blank"
            >
              <Button
                className="mx-2 btn-sm hover:text-primary-focus"
                color="ghost"
              >
                LinkedIn
              </Button>
            </a>
          </Navbar.Center>
        </Navbar>
      </div>
      <div className="drawer-side">
        <label htmlFor="my-drawer-3" className="drawer-overlay"></label>
        <ul className="menu flex p-4 w-80 h-full bg-base-200 text-lg font-bold divide-y-2 justify-center items-center">
          <li>
            <a
              href="https://github.com/markings31?tab=repositories"
              rel="noreferrer"
              target="_blank"
            >
              Portfolio
            </a>
          </li>
          <li>
            <a
              href="https://drive.google.com/file/d/11lCQdcXIpgfkk1M1tyHg-gUx__wBSvwG/view?usp=sharing"
              rel="noreferrer"
              target="_blank"
            >
              Resume
            </a>
          </li>
          <li>
            <a
              href="https://brandonwinecki.substack.com/"
              rel="noreferrer"
              target="_blank"
            >
              VitaLife
            </a>
          </li>
          <li>
            <a
              href="https://github.com/markings31"
              rel="noreferrer"
              target="_blank"
            >
              GitHub
            </a>
          </li>
          <li>
            <a
              href="https://linkedin.com/in/brandonwinecki"
              rel="noreferrer"
              target="_blank"
            >
              LinkedIn
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
