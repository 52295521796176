export default function VitaLifeDisplay() {
  return (
    <div className="container-fluid mx-auto bg-base-200">
      <div className="flex justify-center flex-col">
        <a
          href="https://brandonwinecki.substack.com"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={require("../images/vitalife_product_banner.png")}
            alt="VitaLife Newsletter Banner"
            className="max-w-sm mx-auto mt-4"
          />
        </a>
        <p className="text-center pt-2 pb-6 text-lg font-serif">
          A biweekly newsletter for those looking to create healthy, sustainable
          habits through everyday techniques in cognitive and behavioral
          psychology.
        </p>
        <div className="flex flex-col xl:flex-row mx-auto gap-5 mb-8">
          <a
            href="https://brandonwinecki.substack.com/p/understanding-habits-and-how-to-manipulate"
            target="_blank"
            rel="noreferrer"
            className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300"
          >
            <img
              src={require("../images/promotion_habits.jpg")}
              alt="Understanding How to Create and Break Habits"
              className="max-w-sm rounded-md"
            />
          </a>
          <a
            href="https://brandonwinecki.substack.com/p/radical-mindfulness-focus-and-happiness"
            target="_blank"
            rel="noreferrer"
            className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300"
          >
            <img
              src={require("../images/promotion_mindfulness.jpg")}
              alt="Radical Mindfulness: How to Increase Focus & Foster Happiness in an Age of Materialism
              "
              className="max-w-sm rounded-md"
            />
          </a>
          <a
            href="https://brandonwinecki.substack.com/p/the-silent-epidemic-problems-with"
            target="_blank"
            rel="noreferrer"
            className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300"
          >
            <img
              src={require("../images/promotion_sleep.jpg")}
              alt="Buddhism's Impact in the Stressed & Overwhelmed West"
              className="max-w-sm rounded-md"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
