import "./App.css";
import Navigation from "./components/Navigation";
import HeroDisplay from "./components/HeroDisplay";
import Portfolio from "./components/Portfolio";
import Footer from "./components/Footer";
import VitaLifeDisplay from "./components/VitaLifeDisplay";

export default function App() {
  /* const [theme, setTheme] = useState("light");

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    setTheme(mediaQuery.matches ? "dark" : "light");

    const handler = () => setTheme(mediaQuery.matches ? "dark" : "light");

    mediaQuery.addEventListener("change", handler);
    return () => mediaQuery.removeEventListener("change", handler);
  }, []);*/

  return (
      <>
        <Navigation />
        <HeroDisplay />
        <VitaLifeDisplay />
        <Portfolio />
        <Footer />
      </>
  );
}
