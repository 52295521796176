export default function Portfolio() {
  return (
    <div className="container py-12 mx-auto">
      <div className="text-2xl font-bold text-center pb-9">Portfolio</div>
      <div className="flex flex-wrap md:flex-row justify-center gap-16">
        <div className="card w-96 shadow-2xl bg-gray-800">
          <figure>
            <img
              src="https://i.ibb.co/GMm5H7d/Bubble-Banner-1.png"
              alt="Bubble Product Banner"
            />
          </figure>
          <div className="card-body">
            <h2 className="card-title">Bubble Notifications</h2>
            <p>
              An easy, powerful, and customizable notifications and chat tool
              for Minecraft servers that enables integration with Discord and
              mobile platforms.
            </p>
            <div className="card-actions justify-center">
              <a
                href="https://github.com/markings31/Bubble"
                target="_blank"
                rel="noreferrer"
              >
                <button className="btn btn-primary">Learn More</button>
              </a>
            </div>
          </div>
        </div>
        <div className="card w-96 shadow-2xl bg-gray-800">
          <figure>
            <img
              src="https://i.imgur.com/zW8gqan.png"
              className="w-52"
              alt="Kartu Product Banner"
            />
          </figure>
          <div className="card-body">
            <h2 className="card-title">Kartu</h2>
            <p>
              A student-driven project dedicated to helping young organizations
              find their dream partner and receive the funding they need to
              succeed.
            </p>
            <div className="card-actions justify-center pt-3">
              <a
                href="https://github.com/markings31/Kartu"
                target="_blank"
                rel="noreferrer"
              >
                <button className="btn btn-primary">Learn More</button>
              </a>
            </div>
          </div>
        </div>
        <div className="card w-96 shadow-2xl bg-gray-800">
          <figure>
            <img
              src={require("../images/yit_logo_transparent.png")}
              alt="Youth In Tune Banner"
              className="w-52"
            />
          </figure>
          <div className="card-body">
            <h2 className="card-title">Youth In Tune (Website Developer)</h2>
            <p>
              A year-long music education program that strives to inspire and
              empower students through providing equal access to the performing
              arts.
            </p>
            <div className="card-actions justify-center">
              <a
                href="https://youthintune.org"
                target="_blank"
                rel="noreferrer"
              >
                <button className="btn btn-primary">Learn More</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
